import { CommonModule } from '@angular/common'
import { Component, forwardRef, Host, Input, OnInit, Provider, SkipSelf, ViewEncapsulation } from '@angular/core'
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms'
import { Form } from '@utils'
import { noop } from 'rxjs'
import { FontModule } from '../../font'
import { TInputType, TTemplateInputType } from './types'

const INPUT_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputFieldComponent),
  multi: true,
}

@Component({
  selector: 'lib-input-field',
  standalone: true,
  imports: [CommonModule, FormsModule, FontModule],
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [INPUT_CONTROL_VALUE_ACCESSOR],
  encapsulation: ViewEncapsulation.None,
})
export class InputFieldComponent implements ControlValueAccessor, OnInit {
  @Input({ required: true }) formControlName: string
  @Input() disabled: boolean = false

  private _type: TInputType = 'text'
  @Input() set type(type: TInputType) {
    this._type = type
    this.templateInputType = this.getTemplateInputType()
  }
  public get type() {
    return this._type
  }

  @Input() min: number
  @Input() max: number
  @Input() minlength: number
  @Input() maxlength: number

  @Input() label?: string
  @Input() helpText?: string
  @Input() placeholder: string = ''

  public get passwordLabel() {
    return this.templateInputType === 'password' ? this.passwordLabels.hidden : this.passwordLabels.visible
  }

  public readonly passwordLabels: { [key: string]: string } = {
    visible: $localize`:@@input.password.hide:MASQUER`,
    hidden: $localize`:@@input.password.show:AFFICHER`,
  }

  public isRequired = false
  public templateInputType: TTemplateInputType = 'text'
  public control: AbstractControl
  private form: Form

  private _value: string | number

  public onChange: (value: string | number) => void = noop
  public onTouched: () => void = noop

  public get hasError() {
    return this.control.invalid && this.control.touched && this.control.dirty
  }

  public set value(value: string | number) {
    this._value = this.parseValue(value)
    this.onChange(this._value)
  }

  public get value() {
    return this._value
  }

  constructor(@Host() @SkipSelf() private readonly controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.form = <Form>this.controlContainer.control
    this.control = this.controlContainer.control.get(this.formControlName)
    this._value = this.control.value

    this.isRequired = this.control.hasValidator(Validators.required)
  }

  writeValue(value: string | number): void {
    this._value = value
  }

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  public togglePasswordVisibility(): void {
    this.templateInputType = this.templateInputType === 'password' ? 'text' : 'password'
  }

  private parseValue(value: string | number): string | number {
    if (this.type === 'integer') {
      return Math.ceil(+value)
    }

    return value
  }

  private getTemplateInputType(): TTemplateInputType {
    return this.type === 'integer' || this.type === 'decimal' ? 'number' : this.type
  }
}
