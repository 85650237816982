import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { TableComponent, TTableData } from '@ui'
import { TIntellectualStimulationAnswers } from '../../../../../life-habits/intellectual-stimulation/types'
import { LucilabCommonModule } from '../../../../lucilab-common/lucilab-common.module'
import { TAssessment, TFormSubmission, TItem } from '../../../types'
@Component({
  selector: 'app-intellectual-answers',
  templateUrl: './intellectual-answers.component.html',
  standalone: true,
  imports: [CommonModule, LucilabCommonModule, TableComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntellectualAnswersComponent implements OnChanges {
  @Input({ required: true }) assessment: TAssessment
  @Input({ required: true }) submission: TFormSubmission<TIntellectualStimulationAnswers>
  @Input({ required: true }) items: TItem[]

  public tableData: TTableData<{ activity: string; frequency: number }>

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.submission) {
      this.tableData = this.makeTableData()
    }
  }

  makeTableData(): TTableData<{ activity: string; frequency: number }> {
    return {
      columns: {
        activity: { label: $localize`:@@shared.life-habits.components.intellectual-answers.activity:Activité` },
        frequency: { label: $localize`:@@shared.life-habits.components.intellectual-answers.frequence:Fréquence` },
      },
      rows: [
        ...Object.entries(this.submission.answers.details ?? {}).map(([itemId, answer]) => ({
          activity: this.items.find((item) => item.internal_id === itemId).name,
          frequency: answer.frequency,
        })),
        ...Object.values(this.submission.answers.customActivities?.selected).map((itemId) => ({
          activity: itemId,
          frequency: this.submission.answers.customActivities.details[itemId].frequency,
        })),
        {
          activity: $localize`:@@shared.life-habits.components.intellectual-answers.group-activity:Activités de groupe`,
          frequency: this.submission.answers.groupActivity.frequency,
        },
      ],
    }
  }
}
