import { inject, NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { environment } from 'environment/environment'
import { map } from 'rxjs'
import { CanMatchUserExtraInfo } from 'src/app/misc/guards/user-extra-info.guard'
import { articleUserAuthGuardFn } from './article-library/article-user-auth.guard'
import { SinglePageArticleComponent } from './article-library/components/single-page-article/single-page-article.component'
import { TagsListComponent } from './article-library/components/tags-list/tags-list.component'
import { PageAfterUserDeletionComponent } from './dashboard/components/page-after-user-deletion/page-after-user-deletion.component'
import { participantGuardFn } from './dashboard/guards/participant.guard'
import { UserConsultedLibraryResolverFn } from './dashboard/resolvers/user-consulted-library.resolver'
import { routeParamsName } from './library-main-layout/route-params'
import { UnsubscribeNotificationComponent } from './misc/components/unsubscribe-notification/unsubscribe-notification.component'
import { accetpedTermsGuardFn } from './misc/guards/accepted-terms.guard'
import { AdvisorGuardFn } from './misc/guards/advisor.guard'
import { authGuardFn } from './misc/guards/auth.guard'
import { CanActivateAccount } from './misc/guards/can-activate-account.guard'
import { guidedTourCompletedGuardFn } from './misc/guards/guided-tour-completed.guard'
import { publicGuardFn } from './misc/guards/public.guard'
import { sequentialGuardFn } from './misc/guards/sequential.guard'
import { PreloadService } from './misc/services/preload.service'
import { HomeComponent } from './shared/components/home/home.component'
import { MaintenanceComponent } from './shared/components/maintenance/maintenance.component'
import { TermsComponent } from './shared/components/terms/terms.component'
import { LifeHabitsService } from './shared/life-habits/service'
import { ResolveArticleId } from './shared/resolvers/article-id.resolver'

const routes: Routes = [
  {
    path: 'demo',
    canActivate: [() => !!environment.featureFlags?.demo],
    loadChildren: () => import('./demo/demo.module').then((m) => m.DemoModule),
  },
  {
    path: '',
    redirectTo: 'public',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: async () => (await import('./auth/auth.module')).AuthModule,
    canActivate: [authGuardFn],
  },
  {
    path: 'inscription',
    loadChildren: () => import('./registration/registration.module').then((m) => m.RegistrationModule),
  },
  {
    path: 'connexion',
    loadComponent: () => import('./login/login.component').then((c) => c.LoginComponent),
    canActivate: [authGuardFn],
  },
  {
    path: 'coach',
    loadChildren: async () => (await import('./coach/coach.module')).CoachModule,
    canMatch: [AdvisorGuardFn, accetpedTermsGuardFn],
  },
  {
    path: 'dashboard',
    loadChildren: async () => (await import('./dashboard/dashboard.module')).DashboardModule,
    canActivate: [
      sequentialGuardFn([
        articleUserAuthGuardFn(false),
        participantGuardFn,
        CanMatchUserExtraInfo(),
        guidedTourCompletedGuardFn,
        accetpedTermsGuardFn,
      ]),
    ],
  },
  {
    path: 'public',
    loadChildren: async () =>
      (await import('./dashboard-unregistered/dashboard-unregistered.module')).DashboardUnregisteredModule,
    canActivate: [sequentialGuardFn([articleUserAuthGuardFn(true), publicGuardFn, authGuardFn, CanActivateAccount()])],
  },
  {
    path: 'categories',
    resolve: [UserConsultedLibraryResolverFn],
    loadChildren: () =>
      import('./library-main-layout/library-main-layout.module').then((m) => m.LibraryMainLayoutModule),
    canActivate: [CanActivateAccount()],
  },
  {
    path: 'guided-tour',
    loadChildren: () => import('./guided-tour/guided-tour.module').then((module) => module.GuidedTourModule),
    canMatch: [participantGuardFn],
  },
  {
    path: 'terms',
    component: TermsComponent,
    canActivate: [guidedTourCompletedGuardFn],
  },
  {
    path: 'rencontre',
    loadChildren: async () => (await import('./appointment-video/video-apt.module')).VideoAptModule,
    data: {
      preload: true,
    },
  },
  {
    path: 'podcast',
    component: HomeComponent,
    canActivate: [CanActivateAccount()],
    children: [
      {
        path: '',
        loadComponent: () => import('./podcast-page/podcast-page.component').then((c) => c.PodcastPageComponent),
      },
      {
        path: ':seasonId',
        loadComponent: () => import('./podcast-page/podcast-page.component').then((c) => c.PodcastPageComponent),
      },
      {
        path: ':seasonId/episode/:episodeId',
        loadComponent: () => import('./podcast-page/podcast-page.component').then((c) => c.PodcastPageComponent),
      },
    ],
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
  },
  {
    path: 'unsubscribe',
    component: UnsubscribeNotificationComponent,
  },
  {
    path: 'user-extra-info',
    loadComponent: () =>
      import('./user-extra-info/user-extra-info.component').then((module) => module.UserExtraInfoComponent),
    canMatch: [participantGuardFn],
  },
  {
    path: 'terms',
    component: TermsComponent,
    canActivate: [CanMatchUserExtraInfo(), guidedTourCompletedGuardFn],
  },
  {
    path: 'account-closed',
    component: PageAfterUserDeletionComponent,
  },
  {
    path: 'guided-tour',
    loadChildren: () => import('./guided-tour/guided-tour.module').then((module) => module.GuidedTourModule),
    canMatch: [participantGuardFn, CanMatchUserExtraInfo()],
  },
  {
    path: 'about',
    loadComponent: () => import('./about/about.component').then((module) => module.AboutComponent),
    canActivate: [CanActivateAccount()],
  },
  {
    path: 'faq',
    loadComponent: () => import('./faq-contentful/faq/faq.component').then((module) => module.FaqComponent),
    canActivate: [CanActivateAccount()],
  },
  {
    path: 'habitudes-de-vie',
    loadChildren: () => import('./life-habits/life-habits.module').then((m) => m.LifeHabitsModule),
    canActivate: [() => !!environment.featureFlags?.lifeHabits, participantGuardFn], // TODO update guard
  },
  {
    path: 'objectifs',
    component: HomeComponent,
    loadChildren: () => import('./objectives/objectives.module').then((m) => m.ObjectivesModule),
    canActivate: [() => !!environment.featureFlags?.lifeHabits, participantGuardFn], // TODO update guard
    data: {
      footerEnabled: false,
    },
  },
  {
    path: 'remplir-habitudes-de-vie',
    component: HomeComponent,
    loadChildren: () =>
      import('./complete-life-habits-form/complete-life-habits-form.module').then(
        (m) => m.CompleteLifeHabitsFormModule,
      ),
    canActivate: [
      () => !!environment.featureFlags?.lifeHabits,
      participantGuardFn,
      () =>
        inject(LifeHabitsService)
          .hasCompletedAssessment()
          .pipe(map((hasCompletedAssessment) => !hasCompletedAssessment)),
    ],
  },
  {
    path: 'articles/:articleId',
    component: HomeComponent,
    data: {
      breadcrumbVersion: 2,
    },
    children: [
      {
        path: '',
        component: SinglePageArticleComponent,
        resolve: { articleId: ResolveArticleId },
        data: {
          reuseComponent: false,
        },
        canActivate: [CanActivateAccount()],
      },
    ],
  },
  {
    path: `themes/:${routeParamsName.themeId}`,
    component: HomeComponent,
    children: [
      {
        path: '',
        component: TagsListComponent,
      },
    ],
    canActivate: [CanActivateAccount()],
  },
  {
    path: 'a-propos',
    component: HomeComponent,
    loadChildren: () => import('./corpo-pages/corpo-pages.module').then((m) => m.CorpoPagesModule),
  },
  {
    path: 'avis-juridiques',
    component: HomeComponent,
    loadChildren: () => import('./legal-notice/legal-notice.module').then((m) => m.LegalNoticeModule),
  },
  {
    path: ':account',
    loadComponent: () =>
      import('./account-landing-page/account-landing-page.component').then((c) => c.AccountLandingPageComponent),
    canActivate: [CanActivateAccount('public')],
  },
  {
    path: '**',
    redirectTo: 'public',
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload',
      anchorScrolling: 'enabled',
      urlUpdateStrategy: 'eager',
      preloadingStrategy: PreloadService,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
