import { ChangeDetectionStrategy, Component } from '@angular/core'
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { SelectComponent } from '../../inputs/select/select.component'

@Component({
  selector: 'lib-uikit-demo-select',
  templateUrl: './select.component.html',
  standalone: true,
  imports: [SelectComponent, ReactiveFormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DemoSelectComponent {
  formGroup = new UntypedFormGroup({
    default: new UntypedFormControl(null, [Validators.required]),
    disabled: new UntypedFormControl({ disabled: true, value: '' }),
  })

  options = [
    { value: 1, label: 'Famille et amis' },
    { value: 2, label: 'Médias (journaux, radio, etc.)' },
    { value: 3, label: 'Réseaux sociaux ou publicités' },
    { value: 4, label: 'Professionnel de la santé' },
    { value: 5, label: 'Conférence, webinar, forum ou évènement' },
    { value: 6, label: 'Autre (précisez)' },
  ]
}
