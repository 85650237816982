import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlSegment } from '@angular/router'
import { of } from 'rxjs'
import { map } from 'rxjs/operators'
import { getToken } from 'src/app/auth/utils/token-utils'
import { UserService } from '../services/user/user.service'

export const authGuardFn: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const userService = inject(UserService)
  const router = inject(Router)

  const getSegmentsFn = (child: ActivatedRouteSnapshot): UrlSegment[] => [
    ...child.url,
    ...child.children.map(getSegmentsFn).flat(),
  ]
  const segments = getSegmentsFn(route)

  if (segments.some((segment) => segment.path.includes('forgot-password'))) {
    return true
  }

  if (getToken()) {
    return userService.user$.pipe(
      map((user) => {
        if (user.is_coach) {
          return true
        } else if (!user.anonymous && !segments.some((urlSegment) => urlSegment.path.includes('confirm-email'))) {
          return router.createUrlTree(['/dashboard'], { queryParams: route.queryParams })
        }
        return true
      }),
    )
  } else {
    return of(true)
  }
}
