<table mat-table [dataSource]="rows" class='mat-elevation-z2'>
  <ng-container *ngFor="let column of columns" [matColumnDef]="column.id">
    <th mat-header-cell *matHeaderCellDef class='!font-bold'>{{column.label}}</th>
    <td mat-cell *matCellDef="let row" [ngClass]="row.classes">
      {{row[column.id]}}
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns">
  </tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  <tr class="mat-row text-center" *matNoDataRow>
    <td [colSpan]="displayedColumns.length" i18n='@@shared.table.no-data.label'>Aucune donnée disponible</td>
  </tr>
</table>
