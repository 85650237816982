<div class='select-component flex flex-col gap-1'>
  <label label-2 bold [for]='id' *ngIf="label" [ngClass]="{'field-required': isRequired}">{{label}}</label>
  <span caption-4 class='helpText' *ngIf="helpText">{{helpText}}</span>
  <ng-select [ngClass]="{'error': hasError}" [placeholder]="placeholder" [required]="isRequired" [clearable]="false"
    [(ngModel)]='value' [disabled]='disabled || control!.disabled' [searchable]="false" class='custom' [items]="options"
    bindLabel="label" bindValue="value">
    <ng-template ng-option-tmp let-item="item">
      <span label-2 [title]="item.label">{{item.label}}</span>
    </ng-template>
  </ng-select>
  <ng-container *ngIf="hasError">
    <ng-content select="lib-validation-indicator" />
  </ng-container>
</div>
