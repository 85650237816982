<div
  class='theme-dashboard bg-white bg-opacity-75 rounded-xl flex justify-center items-center gap-6 py-[14px] px-4 tablet:px-0'>
  <div class='flex gap-2'>
    <img class='self-start' src='./assets/img/calendar.svg'>
    <p caption-4>
      <span i18n='@@life-habits.components.assessment-update-cta.date'>En Date du</span>
      <span bold> {{ latestAssessmentDate | date: 'dd MMMM yyyy' }}</span>
    </p>
  </div>

  <button secondary rounded small (click)="openDialog()" class='whitespace-nowrap'
    data-e2e-id='assessment-update-cta.update' pendoClass="open-update-assessment-dialog"
    i18n='@@life-habits.components.assessment-update-cta.update'>
    Mettre à jour
  </button>
</div>
