import { Directive } from '@angular/core'

@Directive({
  selector: '[libHideFormRequiredIndicator]',
  standalone: true,
  host: {
    class: 'hide-form-required-indicator',
  },
})
export class HideFormRequiredIndicatorDirective {}
