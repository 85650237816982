import { HttpClient, HttpContext } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { EDomain } from '@types'
import { environment } from 'environment/environment'
import { EMPTY, Observable, catchError, expand, iif, map, of, reduce, shareReplay, take, throwError } from 'rxjs'
import { Article } from '../content/article/types'
import { IS_SNACKBAR_SHOWED } from '../http-contexts/show-snackbar.context'
import { PaginatedObject } from '../interfaces/paginated-object'
import {
  EAssessmentStatus,
  TAssessment,
  TAttemptStatus,
  TFormSubmission,
  TGroupItems,
  TIdea,
  TItem,
  TToolbox
} from './types'

@Injectable({
  providedIn: 'root',
})
export class LifeHabitsService {
  private readonly apiUrl = environment.apiUrl

  constructor(private readonly httpClient: HttpClient) {}

  public getCurrentAssessment(): Observable<TAssessment | null> {
    return this.httpClient
      .get<TAssessment>(`${this.apiUrl}life-habits/assessments/current/`, {
        context: new HttpContext().set(IS_SNACKBAR_SHOWED, false),
      })
      .pipe(
        catchError((error) => {
          if (error.status === 404) {
            return of(null).pipe(take(1))
          }
          return throwError(() => error)
        }),
      )
  }

  /**
   * Fetch assessments from the API for the current user
   * @param filters Object containing the filters to apply to the request.
   * Keys are the field names and values are the values to filter on
   * @param orderBy String containing the field names to order the results by.
   * Prepend with '-' to order in descending order.
   * Separated by commas to order by multiple fields
   * @returns Observable of the assessments
   */
  public fetchAssessments(options: {
    filters?: Partial<Pick<TAssessment, 'status' | 'user'>>
    orderBy?: string
  }): Observable<TAssessment[]> {
    const computeParams = ({ filters, orderBy } = options) => {
      if (!orderBy) {
        return filters
      }
      return { ...filters, ordering: orderBy }
    }
    return this.httpClient
      .get<PaginatedObject<TAssessment>>(`${this.apiUrl}life-habits/assessments/`, { params: computeParams() })
      .pipe(map((response) => response.results))
  }

  public hasCompletedAssessment() {
    return this.fetchAssessments({
      filters: { status: EAssessmentStatus.completed },
    }).pipe(map((assessments) => !!assessments.length))
  }

  public getGroupItems(domain: EDomain, params: { version?: string } = {}): Observable<TGroupItems> {
    return this.httpClient
      .get<TGroupItems>(`${this.apiUrl}life-habits/${domain}/items/`, {
        params,
      })
      .pipe(shareReplay())
  }

  public getItems(domain: EDomain, params: { version?: string } = {}): Observable<TItem[]> {
    return this.getGroupItems(domain, params).pipe(
      map((groupItems) => groupItems.groups.flatMap((group) => group.items)),
    )
  }

  public getItem(domain: EDomain, itemInternalId: string): Observable<TItem> {
    return this.getItems(domain).pipe(
      map((items) => items.find((item) => item.internal_id === itemInternalId)),
      shareReplay(),
    )
  }

  public getItemArticles(domain: EDomain, itemInternalId: string): Observable<PaginatedObject<Article>> {
    return this.httpClient.get<PaginatedObject<Article>>(
      `${this.apiUrl}life-habits/${domain}/items/${itemInternalId}/articles/`,
    )
  }

  public getFormSubmission<T>(id: number, params: { user?: number } = {}): Observable<TFormSubmission<T>> {
    return this.httpClient.get<TFormSubmission<T>>(`${this.apiUrl}life-habits/form-submissions/${id}/`, {
      params,
    })
  }

  public sendFormSubmission<T>(formSubmission: TFormSubmission<T>): Observable<TFormSubmission<T>> {
    if (!formSubmission.id) {
      return this.httpClient.post<TFormSubmission<T>>(`${this.apiUrl}life-habits/form-submissions/`, formSubmission)
    }

    return this.httpClient.put<TFormSubmission<T>>(
      `${this.apiUrl}life-habits/form-submissions/${formSubmission.id}/`,
      formSubmission,
    )
  }

  public getIdeas(domain: EDomain, itemInternalId: string) {
    return this.httpClient
      .get<PaginatedObject<TIdea>>(`${this.apiUrl}life-habits/${domain}/items/${itemInternalId}/ideas/`, {
        params: { page_size: 100 },
      })
      .pipe(map((response) => response.results))
  }

  public getIdea(
    domain: EDomain,
    itemInternalId: string,
    ideaInternalId: string,
    showSnackBarError = true,
  ): Observable<TIdea> {
    return this.httpClient
      .get<TIdea>(`${this.apiUrl}life-habits/${domain}/items/${itemInternalId}/ideas/${ideaInternalId}/`, {
        context: new HttpContext().set(IS_SNACKBAR_SHOWED, showSnackBarError),
      })
      .pipe(shareReplay())
  }

  public createToolboxAttempt(
    itemInternalId: string,
    ideaInternalId: string,
    moment?: string,
    params?: { on_archive?: 'restore' | 'delete' },
  ) {
    return this.httpClient.post<TToolbox>(
      `${this.apiUrl}life-habits/toolbox/${itemInternalId}/${ideaInternalId}/`,
      {
        moment,
      },
      {
        context: new HttpContext().set(IS_SNACKBAR_SHOWED, false),
        params,
      },
    )
  }

  public getToolbox(itemInternalId: string, showSnackBarError = true): Observable<TToolbox | null> {
    return this.httpClient
      .get<TToolbox>(`${this.apiUrl}life-habits/toolbox/${itemInternalId}/`, {
        context: new HttpContext().set(IS_SNACKBAR_SHOWED, showSnackBarError),
      })
      .pipe(
        catchError((err) => (err.status === 404 ? of(null) : throwError(() => err))),
        shareReplay(),
      )
  }

  public getToolboxes(params: { user?: string; page_size?: number } = {}): Observable<TToolbox[]> {
    return this.httpClient
      .get<PaginatedObject<TToolbox>>(`${this.apiUrl}life-habits/toolbox/`, { params: { page_size: 50, ...params } })
      .pipe(
        expand((response) =>
          iif((): boolean => !!response.next, this.httpClient.get<PaginatedObject<TToolbox>>(response.next), EMPTY),
        ),
        map((response) => (response ? response.results : [])),
        reduce((acc, results) => acc.concat(results), []),
        shareReplay(),
      )
  }

  public updateAttemptStatus(
    itemInternalId: string,
    ideaInternalId: string,
    status: TAttemptStatus,
  ): Observable<TToolbox> {
    return this.httpClient.patch<TToolbox>(`${this.apiUrl}life-habits/toolbox/${itemInternalId}/${ideaInternalId}/`, {
      status,
    })
  }

  public deleteAttempt(itemInternalId: string, ideaInternalId: string) {
    return this.httpClient.delete(`${this.apiUrl}life-habits/toolbox/${itemInternalId}/${ideaInternalId}/`)
  }

  public createBookmark(itemInternalId: string, ideaInternalId: string) {
    return this.httpClient.post(`${this.apiUrl}life-habits/toolbox/${itemInternalId}/${ideaInternalId}/bookmark/`, {
      item_internal_id: itemInternalId,
      idea_internal_id: ideaInternalId,
    })
  }

  public deleteBookmark(itemInternalId: string, ideaInternalId: string) {
    return this.httpClient.delete(`${this.apiUrl}life-habits/toolbox/${itemInternalId}/${ideaInternalId}/bookmark/`)
  }

  public handleBookmark(itemInternalId: string, ideaInternalId: string, checked: boolean) {
    return iif(
      () => checked,
      this.createBookmark(itemInternalId, ideaInternalId),
      this.deleteBookmark(itemInternalId, ideaInternalId),
    )
  }

  public deleteToolbox(itemInternalId: string) {
    return this.httpClient.delete(`${this.apiUrl}life-habits/toolbox/${itemInternalId}/`)
  }
}
