import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'

import { LucilabCommonModule } from '../../../lucilab-common/lucilab-common.module'
import { TAssessment, TFormSubmission, TItem } from '../../types'

import { EDomain } from '@types'
import { Observable } from 'rxjs'
import { LifeHabitsService } from '../../service'
import { IntellectualAnswersComponent } from './intellectual-answers/intellectual-answers.component'
import { NutritionAnswersComponent } from './nutrition-answers/nutrition-answers.component'
import { PhysicalAnswersComponent } from './physical-answers/physical-answers.component'

type TDomainData = {
  items$?: Observable<TItem[]>
  submission$: Observable<TFormSubmission<unknown>>
}

@Component({
  selector: 'app-assessment-answers',
  standalone: true,
  imports: [
    CommonModule,
    LucilabCommonModule,
    IntellectualAnswersComponent,
    PhysicalAnswersComponent,
    NutritionAnswersComponent,
  ],
  templateUrl: './assessment-answers.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentAnswersComponent implements OnInit {
  @Input({ required: true }) assessment!: TAssessment

  public intellectualData: TDomainData
  public physicalData: TDomainData
  public nutritionData: TDomainData

  constructor(private readonly lifeHabitsService: LifeHabitsService) {}

  ngOnInit() {
    this.intellectualData = {
      items$: this.lifeHabitsService.getItems(EDomain.intellectual, { version: this.assessment.intellectual.version }),
      submission$: this.lifeHabitsService.getFormSubmission(this.assessment.intellectual.id, {
        user: this.assessment.user,
      }),
    }

    this.physicalData = {
      items$: this.lifeHabitsService.getItems(EDomain.physical, { version: this.assessment.physical.version }),
      submission$: this.lifeHabitsService.getFormSubmission(this.assessment.physical.id, {
        user: this.assessment.user,
      }),
    }

    this.nutritionData = {
      submission$: this.lifeHabitsService.getFormSubmission(this.assessment.nutrition.id, {
        user: this.assessment.user,
      }),
    }
  }
}
