export enum EColor {
  DEFAULT = 'default',
  GREEN = 'green',
  RED = 'red',
}

export type TTableColumn = {
  label: string
}

export type TTableRow<T> = T & {
  backgroundColor?: EColor
}

export type TTableData<T> = {
  columns: {
    [key in keyof T]: TTableColumn
  }
  rows: TTableRow<T>[]
}
