import { CommonModule } from '@angular/common'
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core'
import Hls, { Events, ManifestParsedData } from 'hls.js'
import Plyr from 'plyr'
import { LucilabCommonModule } from '../../lucilab-common/lucilab-common.module'
import { TVideo } from './types'

@Component({
  selector: 'app-video-player',
  standalone: true,
  imports: [CommonModule, LucilabCommonModule],
  templateUrl: './video-player.component.html',
})
export class VideoPlayerComponent implements AfterViewInit, OnDestroy {
  @ViewChild('videoPlayer') videoElement: ElementRef<HTMLVideoElement>

  @Input() video: TVideo
  @Input() rounded = false
  @Input() autoplay = false

  get currentTime() {
    return this.player?.currentTime
  }

  get playing() {
    return this.player?.playing
  }

  private player: Plyr

  private hls: Hls

  // look here for possible translation object i18n https://github.com/sampotts/plyr/blob/master/src/js/config/defaults.js
  private plyrOptions: Plyr.Options = {
    captions: { active: true },
    i18n: {
      speed: $localize`Vitesse`,
      quality: $localize`Qualité`,
      normal: $localize`Normale`,
    },
  }

  ngAfterViewInit(): void {
    if (!Hls.isSupported() || !this.videoSourceIsM3u8Format()) {
      this.setupBasicPlayer()
    } else {
      this.setupHls()
    }
  }

  ngOnDestroy(): void {
    this.player?.destroy()
  }

  public togglePlaying() {
    if (!this.player) return

    switch (true) {
      case this.player.playing:
        this.player.pause()
        break
      case this.player.paused:
      case this.player.stopped:
        this.player.play()
    }
  }

  public pause() {
    this.player?.pause()
  }

  private setupHls() {
    this.hls = new Hls()
    this.hls.loadSource(this.video.src)
    this.hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => this.setupQualities(event, data))
    this.hls.attachMedia(this.videoElement.nativeElement)
  }

  private setupBasicPlayer(setupPlayerSource = true) {
    this.player = new Plyr(this.videoElement.nativeElement, { ...this.plyrOptions, autoplay: this.autoplay })

    if (this.video.poster) {
      this.player.poster = this.video.poster
    }

    if (setupPlayerSource) {
      this.videoElement.nativeElement.src = this.video.src
    }

    this.player.once('canplay', () => {
      // Set the current time of the video if there is more than 5 seconds left to play
      if (this.player.duration - this.video.currentTime > 5) {
        this.player.currentTime = this.video.currentTime
      }
    })
  }

  private videoSourceIsM3u8Format() {
    return this.video.src?.split('.').pop().toLocaleLowerCase() === 'm3u8'
  }

  private setupQualities(event: Events.MANIFEST_PARSED, data: ManifestParsedData) {
    this.plyrOptions.quality = {
      default: data.levels[this.hls.startLevel].height,
      options: data.levels.map((levels) => levels.height),
      forced: true,
      onChange: (quality) => this.updateQuality(quality),
    }

    this.setupBasicPlayer(false)
  }

  private updateQuality(newQuality: number) {
    const newLevelIndex = this.hls.levels.findIndex((level) => level.height === newQuality)

    if (newLevelIndex !== -1) {
      this.hls.currentLevel = newLevelIndex
    }
  }
}
