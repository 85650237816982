import { CommonModule } from '@angular/common'
import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Host,
  Input,
  OnInit,
  Provider,
  SkipSelf,
  ViewEncapsulation
} from '@angular/core'
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms'
import { NgSelectModule } from '@ng-select/ng-select'
import { noop } from 'rxjs'
import { FontModule } from '../../font'

const SELECT_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectComponent),
  multi: true,
}

@Component({
  selector: 'lib-select',
  standalone: true,
  imports: [CommonModule, NgSelectModule, FormsModule, FontModule],
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [SELECT_CONTROL_VALUE_ACCESSOR],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent<T> implements ControlValueAccessor, OnInit {
  @Input({ required: true }) options: TSelectOption<T>[]
  @Input({ required: true }) id: string
  @Input({ required: true }) formControlName: string
  @Input() placeholder: string = ''
  @Input() label: string
  @Input() helpText: string
  @Input() disabled: boolean = false

  public isRequired = false

  private onChange: (value: T) => void = noop
  private onTouched: () => void = noop
  private _value: T

  get value(): T {
    return this._value
  }

  set value(value: T) {
    this._value = value
    this.onChange(value)
    this.onTouched()
  }

  public control: AbstractControl

  public get hasError() {
    return this.control.invalid && this.control.touched && this.control.dirty
  }

  constructor(@Host() @SkipSelf() private readonly controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.control = this.controlContainer.control.get(this.formControlName)

    this.isRequired = this.control.hasValidator(Validators.required)
  }

  writeValue(value: T): void {
    this.value = value
  }

  registerOnChange(fn: (value: T) => void): void {
    this.onChange = fn
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
  }
}

export type TSelectOption<T> = {
  label: string
  value: T
}
