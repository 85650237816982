import { CommonModule } from '@angular/common'
import { Component, forwardRef, Input, Provider } from '@angular/core'
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms'
import { noop } from 'rxjs'
import { FontModule } from '../../font'

const CHECKBOX_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CheckboxComponent),
  multi: true,
}

@Component({
  selector: 'lib-input-checkbox',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, FontModule],
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [CHECKBOX_CONTROL_VALUE_ACCESSOR],
})
export class CheckboxComponent implements ControlValueAccessor {

  @Input() disabled: boolean = false
  @Input({required: true}) id: string

  private _value: boolean = false

  get value(): boolean {
    return this._value
  }

  set value(value: boolean) {
    this._value = value
    this.onChange(value)
    this.onTouched()
  }

  private onChange: (value: boolean) => void = noop
  private onTouched: () => void = noop

  writeValue(value: boolean): void {
    this.value = value
  }

  registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  toggle(): void {
    if (!this.disabled) {
      this.value = !this.value
    }
  }

}
