<div class='theme-dashboard bg-white rounded-3xl m-4 p-4' *ngIf="assessment">

  <ng-container *ngIf="intellectualData.submission$ | async as intellectualSubmission">
    <app-intellectual-answers *ngIf="intellectualData.items$ | async as intellecualItems"
      [submission]="intellectualSubmission" [items]='intellecualItems' [assessment]="assessment" />
  </ng-container>

  <ng-container *ngIf="physicalData.submission$ | async as physicalSubmission">
    <app-physical-answers *ngIf="physicalData.items$ | async as physicalItems" [submission]="physicalSubmission"
      [items]="physicalItems" [assessment]="assessment" />
  </ng-container>

  <app-nutrition-answers *ngIf="nutritionData.submission$ | async as nutritionSubmission"
    [submission]="nutritionSubmission" [assessment]="assessment" />
</div>
