import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { EColor, TTableData } from '@ui'
import { TranslationService } from '../../../../../life-habits/diet/translation.service'
import { TDietAnswers } from '../../../../../life-habits/diet/types'
import { LucilabCommonModule } from '../../../../lucilab-common/lucilab-common.module'
import { TAssessment, TFormSubmission } from '../../../types'
import { TQuestionCompared } from '../intellectual-answers/types'

@Component({
  selector: 'app-nutrition-answers',
  standalone: true,
  imports: [CommonModule, LucilabCommonModule],
  providers: [TranslationService],
  templateUrl: './nutrition-answers.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NutritionAnswersComponent implements OnChanges {
  @Input({ required: true }) assessment: TAssessment
  @Input({ required: true }) submission: TFormSubmission<TDietAnswers>

  @Input() showGroupCol = true
  @Input() comparedQuestion?: TQuestionCompared

  public readonly itemsTranslation = this.translationService.itemTranslation

  public tableData: TTableData<{
    group?: string
    frequency: string
    detail: string
    portion?: string
  }>

  constructor(private readonly translationService: TranslationService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.submission) {
      this.tableData = this.makeTableData()
    }
  }

  private makeTableData(): TTableData<{
    group?: string
    frequency: string
    detail: string
    portion?: string
  }> {
    const getRowColor = (itemId: string) => {
      if (!this.comparedQuestion) return EColor.DEFAULT
      if (this.comparedQuestion[itemId] === 'positive') return EColor.GREEN
      if (this.comparedQuestion[itemId] === 'negative') return EColor.RED
      return EColor.DEFAULT
    }

    return {
      columns: {
        ...(!this.comparedQuestion && {
          group: { label: $localize`:@@shared.life-habits.components.nutrition-answers.group:Groupe` },
        }),
        frequency: { label: $localize`:@@shared.life-habits.components.nutrition-answers.frequency:Fréquence` },
        detail: { label: $localize`:@@shared.life-habits.components.nutrition-answers.detail:Détail` },
        portion: { label: 'Portion' },
      },
      rows: [
        ...Object.entries(this.submission.answers ?? {}).map(([itemId, answer]) => ({
          group: this.translationService.itemTranslation[itemId],
          frequency: this.translationService.choicesLabels[answer?.frequence]?.label,
          detail: this.translationService.choicesLabels[answer?.details]?.label,
          portion: answer?.portion,
          backgroundColor: getRowColor(itemId),
        })),
      ],
    }
  }
}
