import { EStatus } from './common'

export type TAssessmentDomain = {
  id: number
  version: string
  status: EStatus
  submitted_at?: string
  score?: {
    categories: {
      [key: string]: {
        value: number
        recommended: boolean
      }
    }
  }
}

export enum EAssessmentStatus {
  inProgress = 'in-progress',
  completed = 'completed',
}

export type TAssessment = {
  id: number
  user: number
  status: EAssessmentStatus
  completed_at?: string
  nutrition: TAssessmentDomain
  physical: TAssessmentDomain
  intellectual: TAssessmentDomain
}
