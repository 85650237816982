import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { TTableData, TableComponent } from '@ui'
import { TPhysicalActivitiesAnswers } from '../../../../../life-habits/physical-activity/types'
import { LucilabCommonModule } from '../../../../lucilab-common/lucilab-common.module'
import { intensitiesTranslation } from '../../../translation/get-intensity-translation'
import { sedentaryLifestyleTranslation } from '../../../translation/get-sedentary-lifestyle-translation'
import { TAssessment, TFormSubmission, TItem } from '../../../types'

@Component({
  selector: 'app-physical-answers',
  standalone: true,
  imports: [CommonModule, LucilabCommonModule, TableComponent],
  templateUrl: './physical-answers.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhysicalAnswersComponent implements OnChanges {
  @Input({ required: true }) assessment: TAssessment
  @Input({ required: true }) submission: TFormSubmission<TPhysicalActivitiesAnswers>
  @Input({ required: true }) items: TItem[]

  public tableData: TTableData<{
    activity: string
    timePerWeek: number
    minutesPerSession: string
    intensity: string
  }>

  private readonly sedentaryLifestyleTranslation = sedentaryLifestyleTranslation
  private readonly intensitiesTranslation = intensitiesTranslation

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.submission) {
      this.tableData = this.makeTableData()
    }
  }

  private makeTableData(): TTableData<{
    activity: string
    timePerWeek: number
    minutesPerSession: string
    intensity: string
  }> {
    return {
      columns: {
        activity: { label: $localize`:@@shared.life-habits.components.physical-answers.activity:Activité` },
        timePerWeek: {
          label: $localize`:@@shared.life-habits.components.physical-answers.week-times:Fois par semaine`,
        },
        minutesPerSession: {
          label: $localize`:@@shared.life-habits.components.physical-answers.minutes-session:Minutes par séance`,
        },
        intensity: { label: $localize`:@@shared.life-habits.components.physical-answers.intensity:Intensité` },
      },
      rows: [
        ...Object.entries(this.submission.answers.details ?? {}).map(([itemId, answer]) => ({
          activity: this.items.find((item) => item.internal_id === itemId).name,
          timePerWeek: answer.sessionsPerWeek,
          minutesPerSession: `${answer.minutesPerSession} min.`,
          intensity: this.intensitiesTranslation[answer.intensity],
        })),
        ...Object.values(this.submission.answers.customActivities?.selected).map((itemId) => ({
          activity: itemId,
          timePerWeek: this.submission.answers.customActivities.details[itemId].sessionsPerWeek,
          minutesPerSession: `${this.submission.answers.customActivities.details[itemId].minutesPerSession} min.`,
          intensity: this.intensitiesTranslation[this.submission.answers.customActivities.details[itemId].intensity],
        })),
        {
          activity: $localize`:@@shared.life-habits.components.physical-answers.sedentary-lifestyle:Style de vie sédentaire`,
          timePerWeek: null,
          minutesPerSession: this.sedentaryLifestyleTranslation[this.submission.answers.sedentaryLifestyle.value],
          intensity: null,
        },
      ],
    }
  }
}
