import { NgClass, NgForOf } from '@angular/common'
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { MatTableModule } from '@angular/material/table'
import { TTableColumn, TTableData } from './types'

@Component({
  selector: 'lib-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  standalone: true,
  imports: [MatTableModule, NgForOf, NgClass],
})
export class TableComponent<T> implements OnChanges {
  @Input({ required: true }) tableData: TTableData<T>

  public columns: {
    id: string
    label: string
  }[]
  public rows: (T & { classes: string[] })[]

  public displayedColumns: string[]

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tableData) {
      this.columns = Object.entries<TTableColumn>(this.tableData.columns).map(([id, column]) => ({
        id,
        label: column.label,
      }))
      this.displayedColumns = Object.keys(this.tableData.columns)

      this.rows = this.tableData.rows.map((row) => {
        const { backgroundColor, ...rest } = row
        const classes: string[] = backgroundColor ? [backgroundColor.toString()] : []
        return { ...(rest as T), classes }
      })
    }
  }
}
