import { environment } from 'projects/environments/environment'
import { CookiesManagementService } from '../services/cookies-management.service'

export const changeLanguage = (selectedLang: string, cookieServ: CookiesManagementService, pathFragment?: string) => {
  cookieServ.setCookieByName('nf_lang', selectedLang)

  if (!environment.multiLanguagesAvailable) return

  let url = location.pathname

  if (pathFragment) {
    url = `/${selectedLang}/${pathFragment}`
  } else {
    // take current URL, remove `/{code}/` from beginning, add `/{selected language code}/`
    url = `/${selectedLang}/${url.slice(4)}`
  }
  location.pathname = url
}
